<template>
  <div class="agreement_container">
    <itemScroll ref="itemScroll">
      <div>
        <component
          v-if="components"
          :agreementData="data"
          :agreementFields="config.agreementFields"
          :signData="config.signNameUrlList"
          @initScroll="initScroll"
          @doSignName="doSignName"
          @scrollTo="scrollTo"
          ref="agreementDOM"
          v-bind:is="components"
        ></component>
        <div
          class="agreemtn_button_submit global_container_center"
          v-if="!data.meet_sign_name"
        >
          <customButton
            class="agreement_button_item"
            size="small"
            @click="doCommit"
          >
            <div class="agreement_button_info">
              <span>确认提交</span>
            </div>
          </customButton>
        </div>
      </div>
    </itemScroll>
    <autoGraph
      v-show="showSignName"
      @closeSign="closeSign()"
      :meetingData="data"
      @getSignNameURL="getSignNameURL"
    ></autoGraph>
  </div>
</template>
<script>
import customButton from "@/components/unit/customButton";
import itemScroll from "@/components/unit/itemScroll";
import autoGraph from "@/components/unit/autograph";
import { defineAsyncComponent } from "vue";
import commonAgreement from "@/components/page/agreement/agreementComs/commonAgreement";
import challengeRoundAgreement from "@/components/page/agreement/agreementComs/challengeRoundAgreement";
import challengeRoundTwoAgreement from "@/components/page/agreement/agreementComs/challengeRoundTwoAgreement";
import pubeixiAgreement from "@/components/page/agreement/agreementComs/pubeixiAgreement";
import weixin from "@/common/weixin";
export default {
  name: "agreement",
  data() {
    return {
      showSignName: false,
      agreementHTML: "",
      config: {
        nullText: /<(div|p)\s*class=[\s\w\"]*><\/(div|p)>/g,
        agreementFields: [],
      },
      signData: {
        id: "",
        sign_name: "",
      },
      data: {},
      config: {
        //不在projectList中的就是挑战赛项目，其他项目均写一份在projectList中
        projectList: [
          {
            projectID: [35, 37, 38],
            projectName: "pubeixi",
          },
        ],
        agreementList: {
          challengeRound: [
            {
              agreement: "challengeRoundAgreement",
              start_time: "", //协议开始使用的时间
              end_time: "2023/07/31 23:59:59", //协议截至使用的时间
            },
            {
              agreement: "challengeRoundTwoAgreement",
              start_time: "2023/08/01 00:00:00",
              end_time: "",
            },
          ],
          pubeixi: [
            {
              agreement: "pubeixiAgreement",
              start_time: "", //协议开始使用的时间
              end_time: "", //协议截至使用的时间
            },
          ],
        },
        signNameUrlList: ["", ""],
        signNameUrlIndex: 0,
      },
      componentsName: "defauleAgreement",
    };
  },
  components: {
    autoGraph,
    customButton,
    itemScroll,
    commonAgreement,
    challengeRoundAgreement,
    challengeRoundTwoAgreement,
    pubeixiAgreement,
  },
  computed: {
    components() {
      console.log(
        `@/components/page/agreement/agreementComs/${this.componentsName}`
      ); // 此句别删，用于数据更新
      return defineAsyncComponent(() =>
        import(
          `@/components/page/agreement/agreementComs/${this.componentsName}`
        )
      );
    },
  },
  created() {
    this.loadDataProject();
    document.body.addEventListener("touchmove", this.clearMove, {
      passive: false,
    });
    document.getElementsByTagName("title")[0].innerHTML = "协议签署";
    // this.loadDoctorInfo();
    if (!this.$route.params.id) {
      weixin.wx.closeWindow();
      return;
    }
    this.signData.id = this.$route.params.id;
    this.loadField();
  },
  methods: {
    loadDataProject() {
      let url = this.$tools.getURL(this.$urls.admin.meetDetail, {
        id: this.$route.params.id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.$tools.setStorage(
            "projectId",
            res.data.project_id,
            sessionStorage
          );
          this.$tools.setStorage(
            "companyId",
            res.data.company_id,
            sessionStorage
          );
          this.loadDoctorInfo(res.data);
        })
        .catch((err) => {});
    },
    loadDoctorInfo() {
      let url = this.$tools.getURL(this.$urls.admin.doctorInfo, {
        id: this.$route.params.doctorId,
        project_id: this.$config.projectId,
      });
      this.$axios
        .get(url)
        .then((res) => {
          if (!this.verifyDoctorData(res.data)) {
            this.isPoup = true;
            this.$router.push({
              path: "/doctorInfo",
              query: { id: res.data.id, meetId: this.$route.params.id },
            });
          }
        })
        .catch((err) => {});
    },

    verifyDoctorData(data) {
      let verifyList = (verifyList = [
        "id_card_number",
        "bank",
        "bank_number",
        "id_card_front",
        "id_card_back",
      ]);
      for (let index in verifyList) {
        let key = verifyList[index];
        if (!data[key]) {
          return false;
        }
      }
      return true;
    },
    clearMove(event) {
      event.preventDefault();
    },
    loadField() {
      this.$axios
        .get(this.$urls.doctor.agreementField)
        .then((res) => {
          this.config.agreementFields = res.data;
          this.loadData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadData() {
      let url = this.$tools.getURL(this.$urls.doctor.meetSign, {
        id: this.signData.id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          for (let k in this.data) {
            if (this.data[k] == null) {
              this.data[k] = "";
            }
          }
          this.config.signNameUrlList = this.data.meet_sign_name?.split(",");
          this.data = this.addMoreFields(this.data);
          let projectObj = this.config.projectList.find((x) =>
            x.projectID.includes(this.data.project_id)
          );
          if (projectObj) {
            let projectName = projectObj.projectName;
            this.componentsName = this.getAgreement(projectName);
          } else {
            if (!this.data.docx_base) {
              this.componentsName = "commonAgreement";
            } else {
              this.componentsName = this.getAgreement("challengeRound");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAgreement(projectName) {
      let agreement = "";
      let meetDate = this.$tools.getDate(this.data.meet_start_time);
      let agreementObj = this.config.agreementList[projectName].find((x) => {
        return (
          (x.start_time == "" ||
            new Date(x.start_time) <= new Date(meetDate)) &&
          (x.end_time == "" || new Date(meetDate) <= new Date(x.end_time))
        );
      });
      if (agreementObj) {
        agreement = agreementObj.agreement;
      }
      return agreement;
    },
    initScroll() {
      this.$refs.itemScroll.initScroll();
    },
    scrollTo(scrollHeight) {
      this.$refs.itemScroll.scrollTo(scrollHeight);
    },
    addMoreFields(item) {
      let data = { ...item };
      let signTime = data.meet_sign_time
        ? data.meet_sign_time
        : this.$tools.getDate();
      let [year, month, day] = signTime.split(/[-\s:]+/);
      data.current_year = year;
      data.current_month = month;
      data.current_day = day;
      return data;
    },
    closeSign() {
      this.showSignName = false;
    },
    getSignNameURL(signNameUrl) {
      // this.config.signNameUrlList.push(signNameUrl)
      this.config.signNameUrlList[this.config.signNameUrlIndex] = signNameUrl;
      this.signData.sign_name = this.config.signNameUrlList
        .filter((x) => x != "")
        .join(",");
      this.closeSign();
    },
    doSignName(index) {
      if (
        this.data.meet_sign_name &&
        this.data.meet_sign_name?.split(",")[index]
      )
        return;
      this.config.signNameUrlIndex = index;
      this.showSignName = true;
    },
    verifyData() {
      return this.$refs.agreementDOM.verifyData();
    },
    doCommit() {
      if (this.loading || !this.verifyData()) return;
      // console.log(this.signData)
      // return
      this.loading = true;
      this.$axios
        .post(this.$urls.doctor.meetSign, this.signData, {}, 4000)
        .then((res) => {
          this.$tips.success({ text: "提交成功" });
          this.data.meet_sign_time = this.$tools.getDate();
          this.data.meet_sign_name = this.signData.sign_name;
          this.$refs.itemScroll.initScroll();
          let type = this.$tools.getStorage("type", sessionStorage) || 0;
          setTimeout(() => {
            if (type) {
              this.$router.push({
                path: "/doctorMeets",
              });
            } else {
              // this.$router.back();
              weixin.wx.closeWindow();
            }
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
  beforeUnmount() {
    document.body.removeEventListener("touchmove", this.clearMove);
  },
};
</script>
<style>
.agreement_container {
  width: 100%;
  height: 100%;
  padding: 4vw;
  background: #fff;
  overflow: hidden;
}
.agreement_content {
  width: 100%;
  background: #f6f6f6;
}
span.agreement_underline {
  text-decoration: underline;
}
.agreement_main {
  height: 100%;
  overflow: hidden;
}
.agreement_main div.agreement_underline {
  border-bottom: 1px solid #000;
}
.agreement_main h1 {
  width: 100%;
  text-align: center;
  font-size: 6vw;
}
.agreement_main h3 {
  width: 100%;
  font-size: 4vw;
  margin: 3vw 0;
}
.agreement_main b {
  font-weight: 500;
}
.agreement_main p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.agreement_main p,
.agreement_main div {
  line-height: 6.8vw;
  width: 100%;
  /* text-indent: 8vw;; */
}
.agreemtn_button_submit {
  width: 100%;
  margin-top: 8vw;
}
.agreement_button_item {
  width: 74.66vw;
  height: 9.4vw;
}
.agreement_sign_info {
  display: flex;
}
.agreement_sign_info > div {
  flex: 1 0 0;
  margin-top: 6vw;
}
.agreement_sign_name {
  height: 16vw;
  display: flex;
  align-items: center;
}
.agreement_sign_name span {
  white-space: nowrap;
}
.agreement_sign_name img {
  width: 24vw;
  height: auto;
}
</style>
